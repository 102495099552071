import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Logo from "../assets/imgs/logo.png";
import { IconRosetteDiscountCheckFilled, IconX } from "@tabler/icons-react";
import { useState } from "react";

function SwapFinishHeader() {
  const [isNotice, setIsNotice] = useState(true);

  return (
    <Box>
      {isNotice && (
        <Flex
          p="12px 16px"
          bg="linear-gradient(90deg, #03F982 -7.95%, #37C3A6 20.29%, #8572DB 61.19%, #AF45EE 115.47%)"
          justify="space-between"
          align="center"
        >
          <Box></Box>
          <Flex align="center" gap="3px">
            <IconRosetteDiscountCheckFilled color="#fff" size="24" />
            <Text
              color="#fff"
              fontSize={{ base: "10px", md: "14px" }}
              fontWeight="300"
            >
              Make sure you are on <strong>swap.xrphealthcare.com</strong> in
              order to avoid scammers.
            </Text>
          </Flex>
          <IconX
            color="#fff"
            cursor="pointer"
            size="24"
            onClick={() => setIsNotice(false)}
          />
        </Flex>
      )}
      <Flex
        align="center"
        justify="space-between"
        pt="16px"
        px={{ base: "16px", md: "64px" }}
        gap="20px"
      >
        <Flex align="center" gap="12px">
          <Image src={Logo} alt="logo" height="48px" width="48px" />
          <Text
            color="#fff"
            fontWeight="700"
            fontSize="24px"
            display={{ base: "none", md: "flex" }}
          >
            XRPH
          </Text>
          <Text
            color="#fff"
            fontWeight="300"
            fontSize="24px"
            display={{ base: "none", md: "flex" }}
          >
            Swap
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default SwapFinishHeader;
