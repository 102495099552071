import { Box, useToast } from "@chakra-ui/react";
import BgImg from "./assets/imgs/bg.svg";
import SwapFinished from "./components/SwapFinished";
function App() {
  const toast = useToast();

  return (
    <Box
      bgColor="#181818"
      minH="100vh"
      bgImage={BgImg}
      bgSize="contain"
      bgRepeat="no-repeat"
      bgPos="center"
    >
      <SwapFinished />
    </Box>
  );
}

export default App;
