import { Button, Flex, Heading, Image, Text } from "@chakra-ui/react";
import SwapFinishHeader from "./SwapFinishHeader";
import FinishImg from "../assets/imgs/finish-img.svg";
import { IconExternalLink } from "@tabler/icons-react";

function SwapFinished() {
  return (
    <>
      <SwapFinishHeader />
      <Flex
        justify="center"
        align="center"
        mt={{ base: "50px", md: "70px" }}
        px={{ base: "16px", md: "64px" }}
      >
        <Flex
          flexDir="column"
          p="40px 16px"
          gap="24px"
          borderRadius="8px"
          border="0.27px solid #36394A"
          bg="#202226"
          maxW="511px"
          w="100%"
          mb="24px"
        >
          <Heading
            color="#fff"
            fontSize={{ base: "18px", md: "24px" }}
            fontWeight="600"
            textAlign="center"
            fontFamily="Inter"
          >
            XRPH swap has finished
          </Heading>
          <Image
            src={FinishImg}
            alt="finish"
            maxW={{ base: "170px", md: "247px" }}
            mx="auto"
            mt="40px"
          />
          <Button
            mt="40px"
            maxW="274px"
            w="100%"
            mx="auto"
            bg="linear-gradient(90deg, #03F982 -7.95%, #37C3A6 20.29%, #8572DB 61.19%, #AF45EE 115.47%)"
            display="flex"
            alignItems="center"
            gap="12px"
            height="56px"
            borderRadius="12px"
            _hover={{
              bg: "linear-gradient(90deg, #03F982 -7.95%, #37C3A6 20.29%, #8572DB 61.19%, #AF45EE 115.47%)",
            }}
            onClick={() => {
              window.open(
                "https://finance.yahoo.com/news/xrp-healthcare-stops-xrp-xrph-050000088.html",
                "_blank"
              );
            }}
          >
            <Text
              color="#fff"
              fontSize="16px"
              fontWeight="600"
              fontFamily="Inter"
            >
              Read more about it here
            </Text>
            <IconExternalLink color="#fff" size="16px" />
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

export default SwapFinished;
